import React, { ReactNode, useState } from "react";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
} from "react-icons/io";

import IconButton from "@Components/IconButton";
import styles from "./ExpandableContainer.module.scss";

type Props = {
  title: string;
  children: ReactNode;
};

const ExpandableComponent = ({ title, children }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={styles.expandableContainer}>
      <div className={styles.expandableHeader} onClick={toggleExpand}>
        <h2
          style={{
            fontSize: "24px",
          }}
        >
          {title}
        </h2>
        <IconButton
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              marginRight: "10px",
              color: "#6b97f6",
            }}
          >
            {isExpanded ? "Collapse" : "Expand"}
          </div>
          {isExpanded ? (
            <IoIosArrowDropupCircle
              size={30}
              onClick={toggleExpand}
              color="#6b97f6"
            />
          ) : (
            <IoIosArrowDropdownCircle
              size={30}
              onClick={toggleExpand}
              color="#6b97f6"
            />
          )}
        </IconButton>
      </div>
      <div
        className={`${styles.expandableContent} ${
          isExpanded ? styles.expandableContentExpanded : ""
        }`}
        style={isExpanded ? { height: "auto" } : { height: "0" }}
      >
        {children}
      </div>
    </div>
  );
};

export default ExpandableComponent;
