import useCurrentUser from "@Hooks/useCurrentUser";
import { useCreateIncompleteApplication } from "@Hooks/useIncompleteApplications";
import { Box } from "@mui/system";
import { defaultState } from "@Types/incomplete_application";
import { hasActionPermissions } from "@Utils/permissions";
import styles from "./Dashboard.module.scss";
import RecentApplications from "./RecentApplicationsDashboard/RecentApplications";
import WelcomeGraphic from "./WelcomeGraphic";

const Dashboard = () => {
  const user = useCurrentUser();

  const { createDeal } = useCreateIncompleteApplication();

  const handleCreateNewIncompleteDeal = () => createDeal(defaultState);

  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.welcomeBanner}>
        <h1 className={styles.greeting}>{`Welcome back, ${
          user?.data.info.firstName ?? ""
        }`}</h1>
        <Box display={{ xs: "none", xl: "block" }}>
          <WelcomeGraphic />
        </Box>
        {hasActionPermissions("deal", "create", user) && (
          <button
            id="create-new-application-button"
            className={styles.button}
            onClick={handleCreateNewIncompleteDeal}
          >
            Create New Application
          </button>
        )}
      </div>

      {hasActionPermissions("deal", "read", user) && <RecentApplications />}
    </div>
  );
};
export default Dashboard;
