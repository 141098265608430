import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import ApplicantFilter from "@Components/Filters/ApplicantFilter";
import DateFilter from "@Components/Filters/DateFilter";
import LenderFilter from "@Components/Filters/LenderFilter";
import ManagerFilter from "@Components/Filters/ManagerFilter";
import SelectFilter from "@Components/Filters/SelectFilter";
import TextFilter from "@Components/Filters/TextFilter";
import {
  DateFilterPreviewProps,
  FilterPreviewProps,
} from "@Components/Filters/types";
import IconButton from "@Components/IconButton";
import { bbStatusToDStatus } from "@Components/Stepper/Stepper";
import Table from "@Components/Table";
import { Column } from "@Components/Table/types";
import useCurrentUser from "@Hooks/useCurrentUser";
import useGetDeals from "@Hooks/useDeals";
import { statusToColor } from "@Pages/Dashboards/RecentApplicationsDashboard/RecentApplications";
import { Deal, DealStatus } from "@Types/deal";
import { formatDateAmerican, formatFirstAndLastNames } from "@Utils/functions";
import { hasSectionPermissions } from "@Utils/permissions";
import styles from "./Applications.module.scss";

export const formatCustomersNames = (deal: Deal) => {
  return (
    `${formatFirstAndLastNames(
      deal?.data?.applicant?.data?.info?.firstName,
      deal?.data?.applicant?.data?.info?.lastName
    )}
    ${
      deal?.data?.coApplicant?.data?.info?.firstName
        ? " / " +
          formatFirstAndLastNames(
            deal?.data?.coApplicant?.data?.info?.firstName,
            deal?.data?.coApplicant?.data?.info?.lastName
          )
        : ""
    }`?.trim() || "N/A"
  );
};

export const formatCollateral = (deal: Deal) =>
  [deal?.data?.info?.vehicle?.make, deal?.data?.info?.vehicle?.model]
    ?.filter((x) => x)
    ?.join(" - ") || "N/A";
const Applications = () => {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const columns: Column<Deal>[] = [
    {
      label: "Applicants",
      value: (deal) => formatCustomersNames(deal),
      hide: !hasSectionPermissions("deal", "applicants", user),
      id: "customer",
      truncate: 15,
      filters: [
        {
          placeholder: "Applicants",
          preview: ApplicantFilter,
          queryPath: [],
        },
      ],
    },

    {
      id: "collateral",
      label: "Collateral",
      truncate: 15,
      hide: !hasSectionPermissions("deal", "collateral", user),
      value: (deal) => formatCollateral(deal),
      sortPath: ["data", "info", "vehicle", "make"],
      filters: [
        {
          placeholder: "Collateral",
          preview: TextFilter,
          queryPath: ["data", "info", "vehicle", "make"],
          partialSearch: true,
          caseInsensitive: true,
        },
      ],
    },
    {
      label: "Lender",
      id: "lender",
      truncate: 15,
      value: (deal) => deal.data?.lender?.data?.info?.name ?? "N/A",
      sortPath: ["data", "info", "lender", "data", "info", "name"],
      filters: [
        {
          placeholder: "Lender",
          preview: LenderFilter,
          queryPath: ["data", "lenderId"],
        },
      ],
    },
    {
      id: "f&imgr",
      label: "F&I mgr",
      truncate: 15,
      value: (deal) =>
        formatFirstAndLastNames(
          deal.data?.user?.data?.info?.firstName,
          deal.data?.user?.data?.info?.lastName
        ),
      sortPath: ["data", "info", "user", "data", "info", "firstName"],

      filters: [
        {
          placeholder: "F&I mgr",
          preview: ManagerFilter,
          queryPath: ["data", "userId"],
        },
      ],
    },
    {
      id: "status",
      label: "Status",
      value: (deal) =>
        bbStatusToDStatus(deal?.data?.info?.status)?.title || "N/A",
      style: (entry) => ({
        fontWeight: 700,
        fontSize: 18,
        background: statusToColor(entry?.data?.info?.status),
        padding: "5px 0px",
        borderRadius: "5px",
        justifyContent: "center",
        width: "100%",
        color: "#FFF",
        maxWidth: "250px",
        whiteSpace: "pre-wrap",
        textAlign: "center",
      }),
      filters: [
        {
          placeholder: "Status",
          preview: SelectFilter,
          queryPath: ["data", "info", "status"],
          valuesForSelect: Object.values(DealStatus).map((x) => ({
            label: x,
            value: x,
          })),
        },
      ],
      sortPath: ["data", "info", "status"],
    },
    {
      id: "dateAdded",
      label: "Date added",
      value: (deal) => {
        const formattedDate = formatDateAmerican(deal?.createdAt, false);
        const formattedTime = formatDateAmerican(deal?.createdAt, true, true);

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              marginLeft: "10px",
              lineHeight: "19px",
            }}
          >
            <div>{formattedDate}</div>
            <div>{formattedTime}</div>
          </div>
        );
      },

      filters: [
        {
          placeholder: "Date added",
          preview: (props: FilterPreviewProps) => (
            <DateFilter {...(props as unknown as DateFilterPreviewProps)} />
          ),
          queryPath: ["createdAt"],
        },
      ],
      sortPath: ["data", "createdAt"],
    },
    {
      id: "actions",
      label: "Actions",
      value: (deal) => (
        <IconButton
          id={`open-deal-${deal?._id}`}
          className={"blueCircle"}
          onClick={() => {
            navigate(`/applications/${deal._id}`);
          }}
        >
          <AiOutlineArrowRight size={18} />
        </IconButton>
      ),
    },
  ];
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Applications</h2>
      <Table<Deal>
        columns={columns.filter((x) => !x.hide)}
        useGetFunction={useGetDeals}
        entityType="applications"
      />
    </div>
  );
};

export default Applications;
