import Tooltip from "@Components/Tooltip";
import {
  RiChat4Line,
  RiChatOffLine,
  RiNotificationLine,
  RiNotificationOffLine,
} from "react-icons/ri";
import styles from "./Switch.module.scss";

type SwitchProps = {
  isActive: boolean;
  toggleFunction: () => void;
};
const Switch = ({ isActive, toggleFunction }: SwitchProps) => {
  return (
    <div className={styles.switch} onClick={toggleFunction}>
      <div className={styles.ballContainer}>
        <div
          className={`${styles.ball} ${
            isActive ? styles.active : styles.inactive
          }`}
        />
      </div>
    </div>
  );
};
export const ChatSwitch = ({ isActive, toggleFunction }: SwitchProps) => {
  return (
    <Tooltip
      tooltipStyle={{
        background: "rgb(243, 243, 243)",
        color: "#000",
        padding: "10px",
        border: "1px solid #8080804a",
        fontWeight: "bold",
        left: 70,
        margin: "auto",
        right: 0,

        top: 80,
        width: 150,
        textAlign: "center",
      }}
      content="Turn chat on/off. (show uther users you are active/inactive)"
    >
      <div className={styles.switch} onClick={toggleFunction}>
        <div className={styles.ballContainer}>
          <div
            className={`${styles.ball} ${
              isActive ? styles.active : styles.inactive
            }`}
          />
        </div>
        {isActive ? (
          <RiChat4Line style={{ color: "black" }} size={20} />
        ) : (
          <RiChatOffLine style={{ color: "black" }} size={20} />
        )}
      </div>
    </Tooltip>
  );
};
export const NotificationSwitch = ({
  isActive,
  toggleFunction,
}: SwitchProps) => {
  return (
    <Tooltip
      tooltipStyle={{
        background: "rgb(243, 243, 243)",
        color: "#000",
        padding: "10px",
        border: "1px solid #8080804a",
        fontWeight: "bold",
        left: 140,
        margin: "auto",
        right: 0,
        top: 80,
        width: 150,
        textAlign: "center",
      }}
      content="Turn notifications sound  on/off."
    >
      <div className={styles.switch} onClick={toggleFunction}>
        <div className={styles.ballContainer}>
          <div
            className={`${styles.ball} ${
              isActive ? styles.active : styles.inactive
            }`}
          />
        </div>

        {isActive ? (
          <RiNotificationLine style={{ color: "black" }} size={20} />
        ) : (
          <RiNotificationOffLine style={{ color: "black" }} size={20} />
        )}
      </div>
    </Tooltip>
  );
};

export default Switch;
