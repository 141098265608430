import { ReactNode, useState } from "react";

import SocketConnectionContext from "./index";

const SocketConnectionProvider = ({ children }: { children: ReactNode }) => {
  const [socketConnected, setSocketConnected] = useState(false);
  return (
    <SocketConnectionContext.Provider
      value={{ socketConnected, setSocketConnected }}
    >
      {children}
    </SocketConnectionContext.Provider>
  );
};

export default SocketConnectionProvider;
