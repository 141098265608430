import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { createStipulation } from "@Http/stipulations";
import { MutationError } from "@Types/index";
import { Stipulation } from "@Types/stipulations";

const useCreateStipulations = () => {
  const mutation = useMutation<
    Stipulation[],
    MutationError,
    {
      description: string;
      applicant?: boolean;
      coApplicant?: boolean;
    }[]
  >(createStipulation(), {
    onSuccess: () => {
      toast.success("Successfully created stipulation.  ", {
        position: "top-center",
      });
    },
  });
  return {
    createStipulations: mutation.mutateAsync,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export default useCreateStipulations;
