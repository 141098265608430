import BackButton from "@Components/BackButton";
import Grid from "@Components/Grid";
import Stepper from "@Components/Stepper";
import { getApplication, getApplicationPDF } from "@Http/applications";
import { useQuery } from "@tanstack/react-query";
import { FaPrint } from "react-icons/fa6";
import { IoIosInformationCircle } from "react-icons/io";
import { useParams } from "react-router-dom";
import Applicant from "./Applicants";
import ChangeRequests from "./ChangeRequests";
import Collateral from "./Collateral";
import UploadStipulations from "./Documents/Stipulations";
import UploadDocuments from "./Documents/UploadDocuments";
import FinancialInfo from "./FinancialInfo";
import TradeIn from "./TradeIn";

import IconButton from "@Components/IconButton";
import Paper from "@Components/Paper";
import Tooltip from "@Components/Tooltip";
import { useGetTitleIssue } from "@Hooks/useTitleIssues";
import UploadTitleIssueDocument from "@Pages/TitleIssues/UploadTitleIssueDocument";
import { DealStatus } from "@Types/deal";
import styles from "./Application.module.scss";
import DealerNotes from "./DealerNotes";
import UploadFundedDocuments from "./Documents/UploadFundedDocuments";
import StatusChange from "./StatusChange/StatusChange";
import Taxes from "./Taxes/Taxes";

const Application = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: deal,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["deal", id],
    queryFn: getApplication(id ?? ""),
  });

  const { titleIssue } = useGetTitleIssue(deal?.data?.titleIssueId);

  if (isLoading) return <>Loading...</>;
  if (error) return <>{`${(error as { message: string })?.message}`}</>;
  return deal ? (
    <Grid container xs={12} spacing={1} className={styles.container}>
      <Grid xs={1.5}>
        <BackButton />
      </Grid>
      <Grid xs={12}>
        <div className={styles.applicationProgress}>
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <h2 className={styles.title}>Application progress</h2>
            <Tooltip
              tooltipStyle={{
                background: "#fff",
                color: "#000",
                padding: "10px",
                border: "1px solid #8080804a",
                fontWeight: "bold",
              }}
              content="If you need a credit application, click this icon to download it."
            >
              <IconButton
                onClick={async () => {
                  await getApplicationPDF(deal)();
                }}
              >
                <FaPrint color="#6b97f6" size={30} />
              </IconButton>
            </Tooltip>
          </div>
          {[
            DealStatus.Countered,
            DealStatus.Approved,
            DealStatus.SentToDealer,
            DealStatus.Dead,
          ].includes(deal?.data?.info?.status) &&
            !deal?.data?.info?.statusReasons?.dealership?.marked && (
              <StatusChange deal={deal} requestedStatus={DealStatus.Dead} />
            )}
        </div>
        <div style={{ marginBottom: "110px" }}>
          <Stepper deal={deal} />
        </div>
      </Grid>

      <Grid xs={12}>
        <Paper title="Upload Title Documents">
          {titleIssue &&
          [DealStatus.Funded, DealStatus.FundingHeld].includes(
            deal?.data?.info?.status
          ) ? (
            <UploadTitleIssueDocument titleIssue={titleIssue} />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "15``px",
                backgroundColor: "#f9f9f9",
                borderRadius: "8px",
                gap: 5,
              }}
            >
              <IoIosInformationCircle
                style={{
                  color: "#6b97f6",
                  fontSize: "40px",
                  marginBottom: "8px",
                }}
              />
              <h2 style={{ textAlign: "center", color: "#757575" }}>
                This section is currently inactive. It will become available
                once the deal is funded.
              </h2>
            </div>
          )}
        </Paper>
      </Grid>

      {deal?.data?.info?.status === DealStatus.SentToDealer ||
      deal?.data?.info?.status === DealStatus.SubmittedForFunding ||
      deal?.data?.info?.status === DealStatus.FundingHeld ||
      deal?.data?.info?.status === DealStatus.Funded ? (
        <>
          <Grid xs={6}>
            <UploadFundedDocuments deal={deal} />
          </Grid>
          <Grid xs={6}>
            <DealerNotes deal={deal} />
          </Grid>
        </>
      ) : (
        deal?.data?.info?.status !== DealStatus.Lead && (
          <>
            <Grid xs={7}>
              <UploadDocuments deal={deal} />
            </Grid>
            <Grid xs={5}>
              <DealerNotes deal={deal} />
            </Grid>
          </>
        )
      )}
      <Grid xs={6}>
        <ChangeRequests deal={deal} />
      </Grid>
      <Grid xs={6}>
        <UploadStipulations deal={deal} refetchDeal={refetch} />
      </Grid>

      <Grid xs={8}>
        <Applicant type="applicant" deal={deal} />
      </Grid>
      <Grid xs={4}>
        <Applicant type="coApplicant" deal={deal} />
      </Grid>
      <Grid xs={8}>
        <Collateral
          collateral={deal?.data?.info?.vehicle}
          type={deal?.data?.info?.type}
        />
      </Grid>
      <Grid xs={4}>
        <TradeIn tradeIn={deal?.data?.info?.price?.trade?.[0]} />
      </Grid>

      <Grid xs={12}>
        <FinancialInfo deal={deal} />
      </Grid>
      <Grid xs={12}>
        <Taxes deal={deal} />
      </Grid>
    </Grid>
  ) : null;
};

export default Application;
