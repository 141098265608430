import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import DateFilter from "@Components/Filters/DateFilter";
import PhoneFilter from "@Components/Filters/PhoneFilter";
import TextFilter from "@Components/Filters/TextFilter";
import {
  DateFilterPreviewProps,
  FilterPreviewProps,
} from "@Components/Filters/types";
import IconButton from "@Components/IconButton";
import Table from "@Components/Table";
import { Column } from "@Components/Table/types";
import useApplicants from "@Hooks/useApplicants";
import { Applicant } from "@Types/applicants";
import { formatDateAmerican, formatPhoneNumber } from "@Utils/functions";
import styles from "./Applicants.module.scss";

const Applicants = () => {
  const columns: Column<Applicant>[] = [
    {
      id: "name",
      label: "Name",
      value: (applicant) =>
        `${applicant?.data?.info?.firstName ?? "N/A"} ${
          applicant?.data?.info?.lastName ?? "N/A"
        }`,
      truncate: 15,
      sortPath: ["data", "info", "firstName"],
      filters: [
        {
          placeholder: "Applicant name",
          preview: TextFilter,
          queryPath: ["data", "info", "firstName"],
          partialSearch: true,
          caseInsensitive: true,
        },
      ],
    },
    {
      id: "address",
      label: "Address",
      value: (applicant) =>
        `${applicant?.data?.info?.currentAddressNumber} ${applicant?.data?.info?.currentAddress}`,
      sortPath: ["data", "info", "currentAddress"],
      truncate: 15,
      filters: [
        {
          placeholder: "Address",
          preview: TextFilter,
          queryPath: ["data", "info", "currentAddress"],
          partialSearch: true,
          caseInsensitive: true,
        },
      ],
    },
    {
      id: "city",
      label: "City",
      value: (applicant) => applicant?.data?.info?.currentCity,
      sortPath: ["data", "info", "currentCity"],
      filters: [
        {
          placeholder: "City",
          preview: TextFilter,
          queryPath: ["data", "info", "currentCity"],
          partialSearch: true,
          caseInsensitive: true,
        },
      ],
    },
    {
      id: "zipCode",
      label: "Zip Code",
      value: (applicant) => applicant?.data?.info?.currentZipCode ?? "",
      sortPath: ["data", "info", "currentZipCode"],
      filters: [
        {
          placeholder: "Zip Code",
          preview: TextFilter,
          queryPath: ["data", "info", "currentZipCode"],
          partialSearch: true,
          caseInsensitive: true,
        },
      ],
    },
    {
      id: "mobilePhone",
      label: "Mobile Phone",
      value: (applicant) =>
        formatPhoneNumber(applicant?.data?.info?.mobilePhone) ?? "",

      sortPath: ["data", "info", "mobilePhone"],
      filters: [
        {
          placeholder: "Mobile Phone",
          preview: PhoneFilter,
          queryPath: ["data", "info", "mobilePhone"],
          partialSearch: true,
          caseInsensitive: true,
        },
      ],
    },
    {
      id: "email",
      truncate: 10,
      label: "Email",
      value: (applicant) => applicant?.data?.info?.email ?? "",
      sortPath: ["data", "info", "email"],
      filters: [
        {
          placeholder: "Email",
          preview: TextFilter,
          queryPath: ["data", "info", "email"],
          partialSearch: true,
          caseInsensitive: true,
        },
      ],
    },
    {
      id: "dateAddedd",
      label: "Date Added",
      value: (applicant) => {
        const formattedDate = formatDateAmerican(applicant?.createdAt, false);
        const formattedTime = formatDateAmerican(
          applicant?.createdAt,
          true,
          true
        );

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              marginLeft: "10px",
              lineHeight: "19px",
            }}
          >
            <div>{formattedDate}</div>
            <div>{formattedTime}</div>
          </div>
        );
      },

      sortPath: ["createdAt"],
      filters: [
        {
          placeholder: "Date added",
          preview: (props: FilterPreviewProps) => (
            <DateFilter {...(props as unknown as DateFilterPreviewProps)} />
          ),
          queryPath: ["createdAt"],
        },
      ],
    },

    {
      id: "actions",
      label: "Actions",
      value: (applicant) => (
        <IconButton
          id={`open-applicant-${applicant?._id}`}
          className={"blueCircle"}
          onClick={() => {
            navigate(`/applicants/${applicant._id}`);
          }}
        >
          <AiOutlineArrowRight size={18} />
        </IconButton>
      ),
    },
  ];

  const navigate = useNavigate();
  return (
    <div className={styles.tableContainer}>
      <h2 className={styles.title}>Applicants</h2>
      <Table<Applicant>
        columns={columns}
        useGetFunction={useApplicants}
        entityType="applicants"
      />
    </div>
  );
};

export default Applicants;
