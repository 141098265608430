import { ChatMenu } from "@Components/Chat/Chat";
import OpenedChat from "@Components/Chat/OpenedChat";
import useGetChannels from "@Hooks/useChannels";
import useCurrentUser from "@Hooks/useCurrentUser";
import { useStickyState } from "@Hooks/useStickyState";
import { useEffect, useState } from "react";

import styles from "./Chat.module.scss";
const Chat = () => {
  const [open, setOpen] = useState(false);
  const user = useCurrentUser();

  const { data } = useGetChannels(
    user?.data?.info?.email,
    undefined,
    user?._id ? true : false
  );
  const visibleChannels = (data ?? [])?.filter(
    (channel) => !channel.data.info.hidden
  );
  const [selectedChannelId, setSelectedChannelId] = useStickyState<
    string | null
  >(null, "channelId");

  const onOpenChannel = (id: string) => {
    setOpen(true);
    setSelectedChannelId(id);
  };

  useEffect(() => {
    if (selectedChannelId && !open) {
      setOpen(true);
    }
  }, []);

  return (
    <div className={styles.container}>
      <ChatMenu
        containerClassName="chatContainerPage"
        onClick={onOpenChannel}
        channels={visibleChannels}
      />
      {open && (
        <div
          style={{
            width: "100%",
            // height: "200px",
            background: "#E7EEFF",
            borderRadius: "5px",
            padding: 15,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <OpenedChat
            selectedChannel={visibleChannels.find(
              (visiblechannel) => visiblechannel._id === selectedChannelId
            )}
            setOpen={setOpen}
          />
        </div>
      )}
    </div>
  );
};
export default Chat;
