import { auth } from "@Firebase/firebase";
import { Stipulation, UpdateStipulationRequest } from "@Types/stipulations";
import axios from "axios";
import { uploadFile } from "./documents";

export const uploadStipulation = (): ((
  stipulationRequest: Stipulation & { file: File }
) => Promise<Stipulation>) => {
  return async ({ file, ...stipulationRequest }) => {
    const token = await auth.currentUser?.getIdToken();

    const uploadedFileResult = await uploadFile(file, token);

    const requestData: UpdateStipulationRequest = {
      ...stipulationRequest,
      fileName: file.name,
      metadata: uploadedFileResult.result.metadata,
      data: {
        ...stipulationRequest.data,
        info: {
          ...stipulationRequest.data.info,
          url: uploadedFileResult.result.fileUrl,
        },
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/stipulation/`,
      requestData,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};

export const createStipulation = (): ((
  request: {
    description: string;
    applicant?: boolean;
    coApplicant?: boolean;
  }[]
) => Promise<Stipulation[]>) => {
  return async (request) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/stipulation/new`,
      request,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};
