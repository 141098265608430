import { uploadStipulation } from "@Http/stipulations";
import { MutationError } from "@Types/index";
import { Stipulation } from "@Types/stipulations";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useUploadStipulation = (
  callback: () => void = () => undefined
) => {
  const mutation = useMutation<
    Stipulation,
    MutationError,
    Stipulation & { file: File }
  >(uploadStipulation(), {
    onSuccess: () => {
      callback();
      toast.success("Successfully uploaded a stipulation and send email.", {
        position: "top-center",
      });
    },
  });
  return {
    uploadStipulation: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};
