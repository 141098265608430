import Button from "@Components/Button";
import Dialog from "@Components/Dialog";
import { DialogActions } from "@Components/Dialog/DialogActions";
import { DialogContent } from "@Components/Dialog/DialogContent";
import Grid from "@Components/Grid";
import IconButton from "@Components/IconButton";
import Checkbox from "@Components/Inputs/Checkbox";
import TextField from "@Components/Inputs/TextField";
import Loading from "@Components/Loading";
import useCreateStipulations from "@Hooks/useCreateStipulations";
import useCurrentUser from "@Hooks/useCurrentUser";
import { Deal } from "@Types/deal";
import mixpanel from "mixpanel-browser";
import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { toast } from "react-toastify";
import styles from "./CreateStipulationDialog.module.scss";

interface Props {
  openCreateDialog: boolean;
  setOpenCreateDialog: Dispatch<SetStateAction<boolean>>;
  deal: Deal;
  refetchDeal: () => void;
}
const CreateStipulationDialog = ({
  openCreateDialog,
  setOpenCreateDialog,
  deal,
  refetchDeal,
}: Props) => {
  const hasCoApplicant = deal?.data?.coApplicant?._id !== undefined;
  const user = useCurrentUser();
  const defaultState = [
    {
      dealId: deal._id,
      description: "",
      applicant: true,
      coApplicant: false,
    },
  ];
  const [stipulations, setStipulations] = useState(defaultState);
  const { createStipulations, loading, error } = useCreateStipulations();
  const handleSendCreateRequest = async () => {
    if (
      stipulations.some(
        (stipulation) => !stipulation.applicant && !stipulation.coApplicant
      )
    ) {
      toast.error("Applicant or co-applicant must be selected", {
        position: "top-center",
      });
      return;
    }
    if (stipulations.some((stipulation) => !stipulation.description)) {
      toast.error("Please fill description", {
        position: "top-center",
      });
      return;
    }
    if (
      stipulations.some((stipulation) => stipulation.applicant) &&
      !deal?.data?.applicant?.data?.info?.email
    ) {
      toast.error("Applicant email is missing", {
        position: "top-center",
      });
      return;
    }
    if (
      stipulations.some((stipulation) => stipulation.coApplicant) &&
      !deal?.data?.coApplicant?.data?.info?.email
    ) {
      toast.error("Co-applicant email is missing", {
        position: "top-center",
      });
      return;
    }

    mixpanel.track("Stipulation request", {
      email: user?.data?.info?.email,
      dealershipName: user?.data?.dealership?.data?.info?.name,
    });
    createStipulations(stipulations)
      .then(() => {
        refetchDeal();
        setOpenCreateDialog(false);
        setStipulations(defaultState);
      })
      .catch(() => {
        toast.error("Error sending stipulation request", {
          position: "top-center",
        });
      });
  };

  return (
    <Dialog
      titleColor="blue"
      id="create-change-request"
      open={openCreateDialog}
      title="Request stipulation"
      closeIcon
      size="xl"
      closeFn={() => {
        setOpenCreateDialog(false);
      }}
      containerStyle={{ width: "80%", margin: "0 10%" }}
    >
      <DialogContent>
        <div>
          <p style={{ marginBottom: 20 }}>
            In order to request a stipulation, please fill out the form below.
            Use the &quot;Add another stipulation&quot; button to add more
            stipulations. Please create a separate row for each document. You
            can request a stipulation from the applicant, the co-applicant, or
            both. Once submitted an email will be sent to the selected parties.
          </p>
        </div>
        {error && (
          <div
            style={{
              color: "red",
              padding: "10px",
              border: "1px solid red",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            {error.message}
          </div>
        )}
        {loading && <Loading size={30} text={"Loading"} />}
        {!loading &&
          stipulations.map((stipulation, index) => (
            <Fragment key={index}>
              <div className={styles.form} style={{ marginBottom: 20 }}>
                <IconButton
                  onClick={() => {
                    setStipulations((prev) => {
                      const newState = [...prev];
                      newState.splice(index, 1);
                      return newState;
                    });
                  }}
                  style={{
                    position: "absolute",
                    right: 20,
                    top: 5,
                    fontSize: 25,
                    height: 30,
                    width: 30,
                    color: "red",
                    zIndex: 1,
                  }}
                >
                  <BsFillTrashFill />
                </IconButton>
                <Grid container spacing={2}>
                  <Grid xs={12} md={8}>
                    <Grid xs={12}>Name of the requested stipulation</Grid>
                    <Grid xs={12}>
                      <TextField
                        onChange={(e) =>
                          setStipulations((prev) => {
                            const newState = [...prev];
                            newState[index].description = e.target.value;
                            return newState;
                          })
                        }
                        placeholder="Name"
                        value={stipulation?.description ?? ""}
                        name="Description"
                        type="text"
                        required
                        id="Description"
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={4}>
                    <Grid xs={12}>Should be uploaded by?</Grid>
                    <Grid
                      xs={12}
                      style={{ paddingTop: 20, paddingLeft: 20 }}
                      container
                    >
                      <Checkbox
                        containerStyle={{
                          display: "inline-block",
                          width: "auto",
                          marginRight: 20,
                        }}
                        onChange={(e) => {
                          setStipulations((prev) => {
                            const newState = [...prev];
                            newState[index].applicant = hasCoApplicant
                              ? e.target.checked
                              : true;
                            return newState;
                          });
                        }}
                        placeholder="applicant"
                        checked={stipulation?.applicant}
                        name={"applicant" + index}
                        id={"applicant" + index}
                      />

                      {hasCoApplicant && (
                        <Checkbox
                          containerStyle={{
                            display: "inline-block",
                            width: "auto",
                          }}
                          onChange={(e) =>
                            setStipulations((prev) => {
                              const newState = [...prev];
                              newState[index].coApplicant = e.target.checked;
                              return newState;
                            })
                          }
                          placeholder="coApplicant"
                          checked={stipulation?.coApplicant}
                          name={"coApplicant" + index}
                          id={"coApplicant" + index}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Fragment>
          ))}
        {!loading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={() => {
                setStipulations((prev) => [
                  ...prev,
                  {
                    dealId: deal._id,
                    description: "",
                    applicant: true,
                    coApplicant: false,
                  },
                ]);
              }}
              style={{
                margin: "20px 0",
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <AiOutlinePlusCircle
                size={30}
                style={{ paddingRight: "10px", color: "green" }}
              />
              <span
                style={{
                  color: "green",
                  fontSize: 20,
                  fontWeight: 600,
                }}
              >
                Add another stipulation
              </span>
            </IconButton>
          </div>
        )}
      </DialogContent>
      {!loading && (
        <DialogActions align="space-between">
          <div></div>
          <Button
            style={{ whiteSpace: "nowrap" }}
            variant={
              stipulations.some(
                (stipulation) =>
                  !stipulation.applicant && !stipulation.coApplicant
              )
                ? "pink"
                : "blue"
            }
            type="button"
            onClick={handleSendCreateRequest}
          >
            {stipulations.some(
              (stipulation) =>
                !stipulation.applicant && !stipulation.coApplicant
            )
              ? "Applicant or co-applicant must be selected"
              : "Save & Send email"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
export default CreateStipulationDialog;
