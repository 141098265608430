import { ReactNode, useCallback, useEffect, useRef } from "react";
import { VscClose } from "react-icons/vsc";

import IconButton from "../IconButton";

import useOnClickOutside from "@Hooks/useClickOutside";

import styles from "./Dialog.module.scss";

type DialogProps = {
  open: boolean;
  children: ReactNode;
  title?: string;
  closeFn: () => void;
  callback?: () => unknown;
  closeIcon?: boolean;
  id: string;
  size?: "xs" | "sm" | "lg" | "md" | "xl";
  titleColor?: "blue" | "default";
  containerStyle?: React.CSSProperties;
  spacing?: boolean;
};

const Dialog = ({
  open,
  size = "sm",
  children,
  containerStyle,
  title,
  titleColor = "default",
  closeFn,
  id,
  closeIcon = false,
  spacing = true,
}: DialogProps) => {
  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const closeFnCallback = useCallback(() => closeFn, [dialogRef]);
  useOnClickOutside(dialogRef, closeFnCallback);
  const handleEsc = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      closeFn?.();
    }
  };

  useEffect(() => {
    if (open) {
      dialogRef.current?.focus();
    }
  }, [open]);

  useEffect(() => {
    dialogRef.current?.addEventListener("keydown", handleEsc);
    return () => {
      dialogRef.current?.removeEventListener("keydown", handleEsc);
    };
  }, [closeFn, dialogRef]);

  return open ? (
    <div className={styles.container}>
      <dialog
        ref={dialogRef}
        id={id}
        data-testid="dialog"
        className={`${styles.dialogContainer} ${styles[size]} `}
        style={containerStyle}
        open={true}
      >
        <div>
          {title ? (
            <div className={styles.dialogHeader}>
              <p className={`${styles.title} ${styles[titleColor]}`}>{title}</p>
              {closeIcon && (
                <IconButton dataTestId="close-dialog-button" onClick={closeFn}>
                  <VscClose size={30} />
                </IconButton>
              )}
            </div>
          ) : (
            <></>
          )}
          <div
            className={`${styles.dialogContent}  ${
              spacing && styles["spacing"]
            }`}
          >
            {children}
          </div>
        </div>
      </dialog>
    </div>
  ) : null;
};

export default Dialog;
