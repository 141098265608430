export interface FormState {
  info: ApplicantInfo;
}

export interface ApplicantInfo {
  firstName: string;
  middleName: string | null;
  middleNameRequired?: boolean;
  lastName: string;
  socialSecurityNumber: string;
  driverLicenseNumber: string;
  drivingLicenseState: string;
  coordinates?: {
    lat: number;
    lon: number;
  };
  driverLicenseIssued: string | null;
  driverLicenseExpires: string | null;
  birthDate: string | null;
  currentState: string;
  currentAddress: string;
  currentAddressNumber: string;
  currentCity: string;
  currentCounty: string;
  currentZipCode: string | null;
  monthsAtCurrentAddress: number | null;
  yearsAtCurrentAddress: number | null;
  previousState: string;
  previousAddress: string;
  previousAddressNumber: string;
  previousCity: string;
  previousCounty: string;
  previousZipCode: string | null;
  annualIncome: number | null;
  monthlyIncome: number | null;
  monthsAtPreviousAddress: number | null;
  yearsAtPreviousAddress: number | null;
  propertyOwnership: PropertyOwnership | null;
  maritalStatus: "Married" | "Not married";
  usCitizen?: "Yes" | "No";
  employmentStatus: EmploymentStatus;
  employer: string;
  monthsAtCurrentJob: number | null;
  yearsAtCurrentJob: number | null;
  previousEmployer: string;
  previousOccupation?: string;
  monthsAtPreviousJob?: number | null;
  yearsAtPreviousJob?: number | null;
  email: string;
  homePhone: string | null;
  jobOccupation: string;
  mobilePhone: string;
  noPreviousEmployment?: boolean;
  additionalIncomes: AdditionalIncome[] | null;
  rentPerMonth: number | null;
  mortgagePerMonth: number | null;
  businessPhone: string | null;
  retirementIncome: number | null;
  sourceOfRetirementIncome: string;
}

export type PropertyOwnership = "Owner" | "Rent" | "Family" | "Other";

export type EmploymentStatus = "Employee" | "Business owner" | "Retired";
export type AdditionalIncome = {
  value: number | null;
  source: string | null;
};
export type Applicant = {
  _id: string;
  data: FormState;
  createdAt?: Date | string;
};

export const initialApplicantState: ApplicantInfo = {
  firstName: "",
  middleName: "",
  lastName: "",
  drivingLicenseState: "",
  driverLicenseNumber: "",
  driverLicenseIssued: null,
  driverLicenseExpires: new Date().toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  }),
  birthDate: null,
  socialSecurityNumber: "",
  homePhone: null,
  mobilePhone: "",
  email: "",
  usCitizen: "Yes",
  maritalStatus: "Married",
  currentAddress: "",
  currentAddressNumber: "",
  currentCity: "",
  currentCounty: "",
  currentState: "",
  employer: "",
  monthsAtCurrentJob: null,
  yearsAtCurrentJob: null,
  jobOccupation: "",
  previousEmployer: "",
  currentZipCode: null,
  monthsAtCurrentAddress: null,
  yearsAtCurrentAddress: null,
  propertyOwnership: null,
  previousAddress: "",
  previousAddressNumber: "",
  previousCity: "",
  previousCounty: "",
  previousState: "",
  previousZipCode: null,
  monthsAtPreviousJob: null,
  yearsAtPreviousJob: null,
  monthsAtPreviousAddress: null,
  yearsAtPreviousAddress: null,
  employmentStatus: "Employee",
  additionalIncomes: [{ value: null, source: null }],
  businessPhone: null,
  monthlyIncome: null,
  mortgagePerMonth: null,
  rentPerMonth: null,
  annualIncome: null,
  sourceOfRetirementIncome: "",
  retirementIncome: null,
};
