import { useNavigate } from "react-router-dom";

import Button from "@Components/Button";
import ApplicantFilter from "@Components/Filters/ApplicantFilter";
import DateFilter from "@Components/Filters/DateFilter";
import TextFilter from "@Components/Filters/TextFilter";
import {
  DateFilterPreviewProps,
  FilterPreviewProps,
} from "@Components/Filters/types";
import Table from "@Components/Table";
import { Column } from "@Components/Table/types";
import useGetTitleIssues from "@Hooks/useTitleIssues";
import { formatCustomersNames } from "@Pages/Applications/Applications";
import { Query } from "@Types/http";
import { TitleIssue } from "@Types/title_issues";
import { formatDateAmerican } from "@Utils/functions";

import styles from "./TitleIssues.module.scss";

export const titleIssuesQuery = {
  $or: [
    {
      "data.info.oldTitleRegistrationInfo.status": {
        $in: ["awaiting", "rejected"],
      },
    },
    {
      "data.info.titleRegistrationInfo.status": {
        $in: ["awaiting", "rejected"],
      },
    },
    {
      "data.info.receiptInfo.status": { $in: ["awaiting", "rejected"] },
    },
  ],
} as { [maybeAnd: string]: Query | string };

const TitleIssuesPreview = () => {
  const navigate = useNavigate();
  const { data: title_issues } = useGetTitleIssues({ query: titleIssuesQuery });

  if (!Array.isArray(title_issues) || !title_issues?.length) return null;

  const columns: Column<TitleIssue>[] = [
    {
      id: "dmv",
      label: "DMV",
      value: (titleIssue) =>
        titleIssue.data.deal?.data?.applicant?.data?.info?.currentState ?? "",
      sortPath: [
        "data",
        "deal",
        "data",
        "applicant",
        "data",
        "info",
        "currentState",
      ],
      filters: [
        {
          placeholder: "DMV",
          preview: TextFilter,
          queryPath: [
            "data",
            "deal",
            "data",
            "applicant",
            "data",
            "info",
            "currentState",
          ],
          partialSearch: true,
          caseInsensitive: true,
        },
      ],
    },
    {
      label: "Applicants",
      value: (title) =>
        title.data.deal ? formatCustomersNames(title.data.deal) : "",
      id: "customer",
      filters: [
        {
          placeholder: "Applicants",
          preview: ApplicantFilter,
          queryPath: [],
        },
      ],
    },

    {
      id: "dateAdded",
      label: "Date added",
      value: (deal) => formatDateAmerican(deal?.createdAt, true),
      filters: [
        {
          placeholder: "Date added",
          preview: (props: FilterPreviewProps) => (
            <DateFilter {...(props as unknown as DateFilterPreviewProps)} />
          ),
          queryPath: ["createdAt"],
        },
      ],
      sortPath: ["data", "createdAt"],
    },
    {
      id: "actions",
      label: "Actions",
      value: (title) => (
        <Button
          className={styles.uploadHere}
          variant="blue"
          id={`open-title-${title?._id}`}
          onClick={() => {
            navigate(`/title_info/${title._id}`);
          }}
        >
          Upload
        </Button>
      ),
    },
  ];
  return (
    <div>
      <Table<TitleIssue>
        columns={columns}
        query={titleIssuesQuery}
        useGetFunction={useGetTitleIssues}
        entityType="title_info"
      />
    </div>
  );
};

export default TitleIssuesPreview;
