import { useState } from "react";
import useCurrentUser from "./useCurrentUser";

export function useBrowserNotifications() {
  const currentUser = useCurrentUser();
  const [permission, setPermission] = useState(Notification.permission);
  const requestPermission = () =>
    Notification.requestPermission().then((permission) => {
      setPermission(permission);
    });
  const playNotificationSound = () => {
    const duration = currentUser?.data?.info?.notificationsDuration;
    const audio = new Audio(`${process.env.PUBLIC_URL}/notification.mp3`);

    if (duration) {
      audio.loop = true;
      audio.play();

      setTimeout(() => {
        audio.loop = false;
        audio.pause();
        audio.currentTime = 0;
      }, duration * 1000);
    } else {
      audio.play();
    }
  };
  const showNotification = (title: string, options?: NotificationOptions) => {
    if (permission === "granted") {
      new Notification(title, {
        ...options,
        icon: `${process.env.PUBLIC_URL}/wfd-logo.png`,
      });

      playNotificationSound();
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission((permission) => {
        if (permission === "granted") {
          new Notification(title, {
            ...options,
            icon: `${process.env.PUBLIC_URL}/wfd-logo.png`,
          });
          playNotificationSound();
        }
      });
    }
  };
  const requestBrowserNotificationPermissions = () => {
    if (Notification.permission === "default") {
      requestPermission();
    }
  };

  return {
    permission,
    requestBrowserNotificationPermissions,
    showNotification,
  };
}
