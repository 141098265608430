import { useEffect, useRef, useState } from "react";

import Button from "@Components/Button";
import TextArea from "@Components/Inputs/TextArea";
import useCurrentUser from "@Hooks/useCurrentUser";
import { useCreateNote } from "@Hooks/useDealerNotes";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Deal } from "@Types/deal";
import { formatDate } from "@Utils/functions";
import { hasSectionPermissions } from "@Utils/permissions";
import styles from "./DealerNotes.module.scss";
type DealerNotesProps = {
  deal: Deal;
};
const DealerNotes = ({ deal }: DealerNotesProps) => {
  const currentUser = useCurrentUser();

  if (!hasSectionPermissions("deal", "notes", currentUser)) return null;

  const notes = deal.data.dealerNotes ?? [];

  const [newNote, setNewNote] = useState("");
  const { createNote, loading } = useCreateNote();

  const handleCreateNote = () => {
    if (newNote && !loading) {
      createNote({
        data: {
          dealId: deal._id,
          info: {
            type: "dealer",
            note: newNote,
            user: {
              firstName: currentUser?.data?.info?.firstName || null,
              lastName: currentUser?.data?.info?.lastName || null,
              email: currentUser?.data?.info?.email || null,
            },
          },
        },
      });
      setNewNote("");
    }
  };
  const chatWindowRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTo({
        top: chatWindowRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [notes]); // Runs every time the messages change

  // detect when pressing Enter we should send the note only when the input is focused
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        e.key === "Enter" &&
        e.shiftKey === false &&
        e.target === document.getElementById("create-note") &&
        !loading
      ) {
        e.preventDefault();
        handleCreateNote();
      }
    };

    document.addEventListener("keyup", handleKeyDown);

    return () => {
      document.removeEventListener("keyup", handleKeyDown);
    };
  }, [handleCreateNote]);

  return (
    <Grid container xs={12}>
      <div className={styles.container}>
        <Grid xs={12}>
          <p className={styles.title}>Notes</p>
        </Grid>
        <Grid container xs={12} className={styles.notesContainer}>
          <Grid xs={12} className={styles.notes} ref={chatWindowRef}>
            {notes
              .filter((note) => note?.data?.info?.note)
              .map((note, index) => (
                <Grid
                  key={index}
                  container
                  xs={12}
                  justifyContent="space-between"
                  alignItems="center"
                  rowSpacing={1}
                  className={styles.itemContainer}
                >
                  <Grid
                    xs={12}
                    className={
                      note.data.info.type === "dealer"
                        ? styles.itemDealer
                        : styles.item
                    }
                  >
                    <span className={styles.noteDescription}>
                      {note?.data?.info?.note}
                    </span>
                    <div
                      className={
                        note.data.info.type === "dealer"
                          ? styles.noteCreatorDealer
                          : styles.noteCreator
                      }
                    >
                      {[
                        note.data?.info?.user?.firstName,
                        note?.data?.info?.user?.lastName,
                        formatDate(note?.createdAt, "medium", true),
                      ]
                        .filter((x) => x)
                        .join(" ")}
                    </div>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid
          xs={12}
          container
          className={styles.createNoteContainer}
          spacing={2}
        >
          <Grid xs={9}>
            <TextArea
              onChange={(e) => setNewNote(e.target.value)}
              placeholder="Add note"
              value={newNote}
              name="note"
              required
              id="create-note"
            />
          </Grid>
          <Grid xs={3}>
            <Button type="button" variant="blue" onClick={handleCreateNote}>
              {loading ? "Sending..." : "Send"}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};
export default DealerNotes;
