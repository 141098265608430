import CurrentUserProvider from "@Contexts/CurrentUserContext/CurrentUserProvider";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { CurrentUser } from "../../App";
import SocketConnectionProvider from "@Contexts/SocketConnectionContext/SocketConnectionProvider";
function RequireAuth({ currentUser }: { currentUser: CurrentUser }) {
  const location = useLocation();
  if (!currentUser) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }
  if (currentUser && !currentUser.emailVerified) {
    return (
      <Navigate to="/sign-in/verify-email" state={{ from: location }} replace />
    );
  }
  return (
    <CurrentUserProvider>
      <SocketConnectionProvider>
        <Outlet />
      </SocketConnectionProvider>
    </CurrentUserProvider>
  );
}

export default RequireAuth;
