/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";

import { auth } from "@Firebase/firebase";

import { HttpQuery } from "@Types/http";
import { CreateUserRequest, User } from "@Types/users";

import { queryClient } from "../App";

export const getUsers = (query?: HttpQuery): (() => Promise<User[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_user/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );

    return response.data.result?.docs ?? [];
  };
};
export const getManagers = (query?: HttpQuery): (() => Promise<User[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/manager/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );

    return response.data.result?.docs ?? [];
  };
};

export const getUser = (id: string): (() => Promise<User>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_user/${id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.result;
  };
};
export const getCurrentUser = (): (() => Promise<User>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken(true);
    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/current_user/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    const user = response.data.result as User;

    return user;
  };
};
export const updateUser = (): ((user: User) => Promise<User>) => {
  return async (user) => {
    const token = await auth.currentUser?.getIdToken();
    const { roles, teams, licenses, ...rest } = user.data;

    if (!roles?.length && !teams?.length) {
      throw new Error(
        "The user must be assigned to at least one role or one team."
      );
    }

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_user/${user._id}`,
      {
        ...user,
        data: {
          ...rest,
          rolesIds: roles?.map((role) => role._id),
          teamsIds: teams?.map((team) => team._id),
        },
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    queryClient.setQueryData<User>(
      [`member-${user._id}`],
      () => response.data.result
    );
    return response.data.result;
  };
};
export const deleteUser = (): ((user: User) => Promise<User>) => {
  return async (user) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.delete(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_user/${user._id}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};

export const disableUser =
  (): ((user: User) => Promise<User>) => async (user) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/disable_user/${user._id}`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };

export const createUser = (): ((user: CreateUserRequest) => Promise<User>) => {
  return async (user) => {
    const token = await auth.currentUser?.getIdToken();
    const { roles, teams, licenses, ...rest } = user.data;
    if (!roles?.length && !teams?.length) {
      throw new Error(
        "The user must be assigned to at least one role or one team."
      );
    }
    const response = await axios.post(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/dealership_user/`,
      {
        ...user,
        data: {
          ...rest,
          rolesIds: roles?.map((role) => role._id),
          teamsIds: teams?.map((team) => team._id),
        },
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};
export const resendRegistrationLink = (): ((id: string) => Promise<User>) => {
  return async (id) => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/send-registration-link/${id}`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};

export const acceptTermsAndConditions = (): (() => Promise<User>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.put(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/accept-terms-and-conditions`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.result;
  };
};
