export interface FormState {
  info: Info;
}

export enum ApplicationParserTypes {
  Automatic = "automatic",
  General = "general",
  Frazer = "frazer",
  Reynolds = "reynolds",
  Dealertrack = "dealertrack",
  Carforsale = "carforsale",
  Dealercarsearch = "dealercarsearch",
}

export type ApplicationParserType = ApplicationParserTypes | undefined;

export interface Info {
  name: string;
  applicationParserType: ApplicationParserType;
  titleManagement?: TitleManagement;
  dealershipNameForDealershipPlatform?: string;
  funding?: {
    paymentMethod?: "ACH" | "Check";
    businessDaysToFund: number;
    enabled?: boolean;
  };
}

export type Dealership = { _id: string; data: FormState };

export type Suspensions = {
  funding: boolean;
  applicationProcessing: boolean;
};

export type TitleManagement = {
  suspensions?: Suspensions;
  isRestricted?: boolean;
  outstandingTitlesFundingLimit?: number;
  outstandingTitlesApplicationsLimit?: number;
  outstandingDeals?: number;
};
