import ApplicantFilter from "@Components/Filters/ApplicantFilter";
import TextFilter from "@Components/Filters/TextFilter";

import IconButton from "@Components/IconButton";
import Table from "@Components/Table";
import { Column } from "@Components/Table/types";
import { Marker, MarkerClusterer } from "@googlemaps/markerclusterer";
import useCurrentUser from "@Hooks/useCurrentUser";
import useGetDeals, { useGetDealsForCustomerDashboard } from "@Hooks/useDeals";
import {
  formatCollateral,
  formatCustomersNames,
} from "@Pages/Applications/Applications";
import { Deal, DealStatus } from "@Types/deal";
import { formatDate, formatPhoneNumber } from "@Utils/functions";
import { hasSectionPermissions } from "@Utils/permissions";
import {
  AdvancedMarker,
  APIProvider,
  Map,
  useMap,
} from "@vis.gl/react-google-maps";
import { useEffect, useRef, useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import Loading from "@Components/Loading";
import styles from "./CustomerDashboard.module.scss";
type Poi = { key: string; location: google.maps.LatLngLiteral; deal: Deal };

type PoiMarkersProps = {
  pois: Poi[];
  clusterColor: string;
  type: "Denied" | "Approved but cancelled" | "Funded";
};
const PoiMarkers = ({ pois, clusterColor, type }: PoiMarkersProps) => {
  const map = useMap();
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
  const clusterer = useRef<MarkerClusterer | null>(null);
  const svg = window.btoa(`
    <svg fill="${clusterColor}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
      <circle  fill="none"  stroke="black" stroke-width="10" cx="120" cy="120" opacity="1" r="70" />
      <circle  fill="${clusterColor}" cx="120" cy="120" opacity="1" r="70" />
    </svg>`);
  const renderer = {
    render: ({
      count,
      position,
    }: {
      count: number;
      position: google.maps.LatLngLiteral | google.maps.LatLng;
    }): google.maps.Marker =>
      new google.maps.Marker({
        label: {
          text: String(count),
          color: "#263184",
          fontSize: "14px",
          fontWeight: "600",
        },
        icon: {
          url: `data:image/svg+xml;base64,${svg}`,
          scaledSize: new google.maps.Size(
            Math.min(count * 10, 150) + Math.max(0, count - 15),
            Math.min(count * 10, 150) + Math.max(0, count - 15)
          ),
        },
        position,
        zIndex: 1000000 - (Number(google.maps.Marker.MAX_ZINDEX) + count),
      }),
  };
  // Initialize MarkerClusterer, if the map has changed
  useEffect(() => {
    if (!map) return;

    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({
        map,
        renderer,
        // @ts-expect-error radius is not in the types
        algorithmOptions: { radius: 50 },
      });
    }
  }, [map]);

  // Update markers, if the markers array has changed
  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  const setMarkerRef = (marker: Marker | null, key: string) => {
    if (marker && markers[key]) return;
    if (!marker && !markers[key]) return;

    setMarkers((prev) => {
      if (marker) {
        return { ...prev, [key]: marker };
      } else {
        const newMarkers = { ...prev };
        delete newMarkers[key];
        return newMarkers;
      }
    });
  };

  const [hovered, setOnHover] = useState<undefined | string>();
  return (
    <>
      {pois.map((poi: Poi) => (
        <AdvancedMarker
          key={poi.key}
          position={poi.location}
          ref={(marker) => setMarkerRef(marker, poi.key)}
          onClick={() => {
            setOnHover((x) => (x !== poi.key ? poi.key : undefined));
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width: 20,
                height: 20,
                borderRadius: 20,
                overflow: "hidden",
                border: "3px solid black",
              }}
            >
              <div
                style={{
                  width: 20,
                  height: 20,
                  background: clusterColor,
                }}
              ></div>
            </div>
            {hovered === poi.key ? (
              <div
                style={{
                  background: "white",
                  border: "1px solid gray",
                  borderRadius: "5px",
                  padding: 5,
                  fontSize: 14,
                  position: "absolute",
                  top: 30,
                }}
              >
                <div>
                  Applicant:{" "}
                  {poi?.deal?.data?.applicant?.data?.info?.firstName +
                    " " +
                    poi?.deal?.data?.applicant?.data?.info?.lastName}
                </div>
                <div>
                  Collateral:
                  {poi?.deal?.data?.info?.vehicle?.year +
                    " " +
                    poi?.deal?.data?.info?.vehicle?.make +
                    " " +
                    poi?.deal?.data?.info?.vehicle?.model}
                </div>
                <div>
                  Amount Financed: $
                  {poi?.deal?.data?.info?.payment?.dealTotal?.toFixed(2) || 0}
                </div>
                <div>
                  Status:
                  {type}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          {/*  <Pin background={'#FBBC04'} glyphColor={'#000'} borderColor={'#000'} /> */}
        </AdvancedMarker>
      ))}
    </>
  );
};

const CustomerDashboardHOC = () => {
  return (
    <>
      {process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? (
        <APIProvider
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          onLoad={() => console.log("Maps API has loaded.")}
        >
          <CustomerDashboard />
        </APIProvider>
      ) : (
        "No api key found"
      )}
    </>
  );
};

// https://developers.google.com/maps/documentation/get-map-id
const CustomerDashboard = () => {
  const { data: deals, isLoading } = useGetDealsForCustomerDashboard({
    query: {
      "data.applicant.data.info.coordinates.lat": { $exists: true },
      "data.applicant.data.info.coordinates.lon": { $exists: true },
    },
    options: {
      limit: 100_000,
      projection: {
        _id: 1,

        "data.info.extractedCreditScores": 1,
        "data.applicant.data.info.firstName": 1,
        "data.applicant.data.info.lastName": 1,
        "data.applicant.data.info.coordinates": 1,
        "data.info.vehicle": 1,
        "data.info.status": 1,
        "data.info.statusHistory": 1,
      },
    },
  });

  const filteredDeals: {
    denied: Deal[];
    approvedNegative: Deal[];
    funded: Deal[];
  } = deals?.reduce<{
    denied: Deal[];
    approvedNegative: Deal[];
    funded: Deal[];
  }>(
    (acc, deal) => {
      if (deal.data.info.status === DealStatus.Funded) {
        return { ...acc, funded: [...acc.funded, deal] };
      }
      if (
        (deal.data.info.status === DealStatus.Cancelled ||
          deal.data.info.status === DealStatus.Dead ||
          deal.data.info.status === DealStatus.DidNotProcess ||
          deal.data.info.status === DealStatus.Denied) &&
        deal.data.info.statusHistory?.find(
          (x) => x.status === DealStatus.Approved
        )
      ) {
        return { ...acc, approvedNegative: [...acc.approvedNegative, deal] };
      }

      if (
        deal.data.info.status === DealStatus.Cancelled ||
        deal.data.info.status === DealStatus.Dead ||
        deal.data.info.status === DealStatus.DidNotProcess ||
        deal.data.info.status === DealStatus.Denied
      ) {
        {
          return { ...acc, denied: [...acc.denied, deal] };
        }
      }
      return acc;
    },
    {
      denied: [],
      approvedNegative: [],
      funded: [],
    }
  ) || {
    denied: [],
    approvedNegative: [],
    funded: [],
  };
  const center = {
    lat:
      ((deals as Deal[]) ?? [])?.reduce(
        (a, b) => a + Number(b?.data?.applicant.data.info.coordinates?.lat),
        0
      ) / (deals?.length || 1),
    lng:
      ((deals as Deal[]) ?? [])?.reduce(
        (a, b) => a + Number(b?.data?.applicant.data.info.coordinates?.lon),
        0
      ) / (deals?.length || 1),
  };
  const deniedLocations: Poi[] = ((filteredDeals.denied as Deal[]) ?? [])?.map(
    (deal) => ({
      deal,
      key: deal._id,
      location: {
        lat: Number(deal?.data?.applicant.data.info.coordinates?.lat),
        lng: Number(deal?.data?.applicant.data.info.coordinates?.lon),
      } as google.maps.LatLngLiteral,
    })
  );
  const approvedNegativeLocations: Poi[] = (
    (filteredDeals.approvedNegative as Deal[]) ?? []
  )?.map((deal) => ({
    deal,
    key: deal._id,
    location: {
      lat: Number(deal?.data?.applicant.data.info.coordinates?.lat),
      lng: Number(deal?.data?.applicant.data.info.coordinates?.lon),
    } as google.maps.LatLngLiteral,
  }));

  const fundedLocations: Poi[] = ((filteredDeals.funded as Deal[]) ?? [])?.map(
    (deal) => ({
      deal,
      key: deal._id,
      location: {
        lat: Number(deal?.data?.applicant.data.info.coordinates?.lat),
        lng: Number(deal?.data?.applicant.data.info.coordinates?.lon),
      } as google.maps.LatLngLiteral,
    })
  );

  // applications

  const user = useCurrentUser();
  const navigate = useNavigate();
  const columns: Column<Deal>[] = [
    {
      label: "Applicants",
      value: (deal) => formatCustomersNames(deal),
      hide: !hasSectionPermissions("deal", "applicants", user),
      id: "customer",
      truncate: 15,
      filters: [
        {
          placeholder: "Applicants",
          preview: ApplicantFilter,
          queryPath: [],
        },
      ],
    },
    {
      label: "Email",
      value: (deal) => deal.data.applicant.data.info.email ?? "",
      hide: !hasSectionPermissions("deal", "applicants", user),
      id: "customer",
      truncate: 15,
    },
    {
      label: "Phone",
      value: (deal) =>
        formatPhoneNumber(
          (deal.data.applicant.data.info.mobilePhone || "") as unknown as string
        ) || "",
      hide: !hasSectionPermissions("deal", "applicants", user),
      id: "customer",
      truncate: 15,
    },
    {
      id: "collateral",
      label: "Collateral",
      truncate: 15,
      hide: !hasSectionPermissions("deal", "collateral", user),
      value: (deal) => formatCollateral(deal),
      sortPath: ["data", "info", "vehicle", "make"],
      filters: [
        {
          placeholder: "Collateral",
          preview: TextFilter,
          queryPath: ["data", "info", "vehicle", "make"],
          partialSearch: true,
          caseInsensitive: true,
        },
      ],
    },
    {
      id: "ContractDate",
      label: "Contract Date",
      value: (deal) =>
        formatDate(deal.data.info.dealDates?.contractDate, "short"),
    },
    {
      id: "Potential last payment date",
      label: "Potential last payment date",
      value: (deal) => formatDate(deal.data.info.potentialEndDate, "short"),
    },

    {
      id: "actions",
      label: "Actions",
      value: (deal) => (
        <IconButton
          id={`open-deal-${deal?._id}`}
          className={"blueCircle"}
          onClick={() => {
            navigate(`/applications/${deal._id}`);
          }}
        >
          <AiOutlineArrowRight size={18} />
        </IconButton>
      ),
    },
  ];

  const prevMonth = new Date();
  prevMonth.setDate(1);
  prevMonth.setHours(0, 0, 0, 0);
  prevMonth.setMonth(prevMonth.getMonth() - 1);

  const futureMonth = new Date();
  futureMonth.setDate(1);
  futureMonth.setHours(0, 0, 0, 0);
  futureMonth.setMonth(futureMonth.getMonth() + 5);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.headerTitle}>Applicants dashboard</h3>
      </div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            marginTop: "100px",
            padding: 20,
            overflow: "hidden",
          }}
        >
          <Loading text="Loading data" />
        </div>
      ) : (
        <>
          <div
            style={{
              minWidth: 500,
              border: "3px solid #f3f3f3",
              padding: 20,
              marginTop: 10,
              borderRadius: 5,
            }}
          >
            <div className={styles.blueTitle}>
              Deal outcome by applicant location (only finished deals)
            </div>
            {process.env.REACT_APP_GOOGLE_MAPS_API_KEY && !isLoading ? (
              <APIProvider
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                onLoad={() => console.log("Maps API has loaded.")}
              >
                <Map
                  id="map"
                  style={{ height: 500 }}
                  mapId={"test"}
                  defaultZoom={6}
                  defaultCenter={center}
                  mapTypeId={"roadmap"}
                >
                  <PoiMarkers
                    pois={deniedLocations}
                    clusterColor="#FF0000"
                    type={"Denied"}
                  />

                  <PoiMarkers
                    pois={approvedNegativeLocations}
                    clusterColor="#c800ff" // dark purple
                    type="Approved but cancelled"
                  />
                  <PoiMarkers
                    pois={fundedLocations}
                    clusterColor="#00ff00"
                    type={"Funded"}
                  />
                </Map>
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", padding: "10px 20px" }}>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        background: "#FF0000",
                        display: "inline-block",
                        marginRight: 10,
                      }}
                    ></div>
                    Denied applications
                  </div>
                  <div style={{ display: "flex", padding: "10px 20px" }}>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        background: "#c800ff",
                        display: "inline-block",
                        marginRight: 10,
                      }}
                    ></div>
                    Approved but dead applications
                  </div>

                  <div style={{ display: "flex", padding: "10px 20px" }}>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        background: "#00ff00",
                        display: "inline-block",
                        marginRight: 10,
                      }}
                    ></div>
                    Funded applications
                  </div>
                </div>
              </APIProvider>
            ) : null}
          </div>
          <div
            style={{
              border: "3px solid #f3f3f3",
              padding: 20,
              marginTop: 10,
              borderRadius: 5,
            }}
          >
            <div className={styles.blueTitle}>
              Potential remarketing oportunities ( loans with expiration date in
              the next 5 months)
            </div>
            <Table<Deal>
              columns={columns.filter((x) => !x.hide)}
              useGetFunction={useGetDeals}
              entityType="applications"
              query={{
                "data.info.status": "funded",
                "data.info.potentialEndDate": {
                  $gte: prevMonth.toISOString(),
                  $lte: futureMonth.toISOString(),
                },
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerDashboardHOC;
