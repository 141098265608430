import Grid from "@mui/system/Unstable_Grid/Grid";
import { useRef, useState } from "react";

import Button from "@Components/Button";
import CircularProgressBar from "@Components/CircularProgressBar";
import Dropzone from "@Components/Dropzone";
import {
  useCreateDocument,
  useCreateFundedDocument,
} from "@Hooks/useDocuments";

import useCurrentUser from "@Hooks/useCurrentUser";
import mixpanel from "mixpanel-browser";
import styles from "./DocumentsDropzone.module.scss";

type DocumentsDropzoneProps = {
  dashed?: boolean;
  fullWidth?: boolean;
  dealId: string;
  printedDocumentId?: string;
  section:
    | "needed-documents"
    | "required-documents"
    | "driver-license"
    | "title-documents";
  dropZoneHeight?: string;
  useDocument?: "create-documents" | "create-funded-documents";
  handleCreate?: (file: File) => void;
  showArrow?: boolean;
  buttonText?: string;
  refetch?: () => void;
};
const DocumentsDropzone = ({
  printedDocumentId,
  section,
  dashed,
  fullWidth,
  dealId,
  dropZoneHeight,
  handleCreate,
  showArrow = true,
  buttonText = "Choose file",
  useDocument = "create-documents",
  refetch = () => {
    return undefined;
  },
}: DocumentsDropzoneProps) => {
  const { createDocument, progress } =
    useDocument === "create-documents"
      ? useCreateDocument(refetch)
      : useCreateFundedDocument(refetch);
  const inputFile = useRef<HTMLInputElement | null>(null);
  const dropzoneRef = useRef(null);
  const [file, setFile] = useState<File | undefined>(undefined);
  const user = useCurrentUser();
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e?.target?.files?.length) {
      handleCreate
        ? handleCreate(e.target.files[0])
        : handleCreateDocument(e.target.files[0]);
    }
  };

  const handleCreateDocument = (file: File) => {
    setFile(file);
    createDocument({
      data: {
        incompleteDealId: null,
        dealId,
        ...(printedDocumentId ? { printedDocumentId } : {}),
        info: {
          name: file.name,
        },
      },
      file: file,
    });
    mixpanel.track(
      useDocument === "create-documents"
        ? "Document uploaded before signing"
        : "Signed document uploaded",
      {
        email: user?.data?.info?.email,
        dealershipName: user?.data?.dealership?.data?.info?.name,
      }
    );

    setFile(undefined);
  };

  return (
    <Grid
      xs={fullWidth ? 12 : 6}
      className={`${styles.dropZone}${dashed ? ` ${styles.dashed}` : ""}`}
      ref={dropzoneRef}
      style={{
        ...(dropZoneHeight
          ? { height: dropZoneHeight, flexDirection: "row-reverse" }
          : {}),
      }}
    >
      {showArrow && (
        <CircularProgressBar
          className={styles.circularProgressBar}
          progress={progress}
        />
      )}
      <div
        className={styles.dropZoneContainer}
        id={`${section}-dropzone-container`}
      >
        <Dropzone
          createDocument={handleCreate ? handleCreate : handleCreateDocument}
          containerRef={dropzoneRef}
        />
        {/* <Arrow className={styles.arrow} /> */}

        {file ? (
          <p className={styles.info}>{file.name ?? ""}</p>
        ) : (
          <p className={styles.info}>Drag files to upload or</p>
        )}

        <Button
          onClick={() => inputFile?.current?.click()}
          className={styles.button}
          variant="blue"
        >
          {buttonText}
          <input
            ref={inputFile}
            type="file"
            id={`${section}-regular-upload-document-input`}
            hidden
            onClick={(e) => {
              (e.target as EventTarget & { value: null }).value = null;
            }}
            onChange={(e) => {
              handleFileChange(e);
            }}
          />
        </Button>
      </div>
    </Grid>
  );
};
export default DocumentsDropzone;
