import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import DateFilter from "@Components/Filters/DateFilter";
import TextFilter from "@Components/Filters/TextFilter";
import {
  DateFilterPreviewProps,
  FilterPreviewProps,
} from "@Components/Filters/types";
import IconButton from "@Components/IconButton";
import Table from "@Components/Table";
import { Column } from "@Components/Table/types";
import { Prescreen } from "@Types/prescreens";
import { formatDateAmerican } from "@Utils/functions";

import { useGetPrescreens } from "@Hooks/usePrescreen";
import styles from "./Prescreens.module.scss";

const Prescreens = () => {
  const columns: Column<Prescreen>[] = [
    {
      id: "name",
      label: "Name",
      value: (prescreen) =>
        `${prescreen?.data?.info?.applicant?.firstName ?? "N/A"} ${
          prescreen?.data?.info?.applicant?.lastName ?? "N/A"
        }`,
      truncate: 15,
      sortPath: ["data", "info", "firstName"],
      filters: [
        {
          placeholder: "Applicant name",
          preview: TextFilter,
          queryPath: ["data", "info", "firstName"],
          partialSearch: true,
          caseInsensitive: true,
        },
      ],
    },

    {
      id: "dateAdded",
      label: "Date Added",
      value: (applicant) => formatDateAmerican(applicant?.createdAt, true),
      sortPath: ["createdAt"],
      filters: [
        {
          placeholder: "Date added",
          preview: (props: FilterPreviewProps) => (
            <DateFilter {...(props as unknown as DateFilterPreviewProps)} />
          ),
          queryPath: ["createdAt"],
        },
      ],
    },

    {
      id: "actions",
      label: "Actions",
      value: (prescreen) => (
        <IconButton
          id={`open-prescreen-${prescreen?._id}`}
          className={"blueCircle"}
          onClick={() => {
            navigate(`/prescreens/${prescreen._id}`);
          }}
        >
          <AiOutlineArrowRight size={18} />
        </IconButton>
      ),
    },
  ];

  const navigate = useNavigate();
  return (
    <div className={styles.tableContainer}>
      <h2 className={styles.title}>Prescreen</h2>
      <Table<Prescreen>
        columns={columns}
        useGetFunction={useGetPrescreens}
        entityType="prescreens"
      />
    </div>
  );
};

export default Prescreens;
