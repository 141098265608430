import { useCallback, useRef, useState } from "react";
import { FaUserCog } from "react-icons/fa";
import { GoChevronDown } from "react-icons/go";

import IconButton from "@Components/IconButton";
import { signOut } from "@Firebase/firebase";
import useChangePassword from "@Hooks/useChangePassword";
import useOnClickOutside from "@Hooks/useClickOutside";
import useCurrentUser from "@Hooks/useCurrentUser";
import TermsAndConditionsDialog from "@Pages/TermsAndConditions/TermsAndConditionsDialog";
import { formatFirstAndLastNames } from "@Utils/functions";

import styles from "./UserMenu.module.scss";

const UserMenu = () => {
  const [open, setOpen] = useState(false);
  const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);
  const user = useCurrentUser();
  const handleOpen = () => setOpen(!open);
  const changePassword = useChangePassword();

  const ref = useRef<HTMLDivElement | null>(null);
  const closeFnCallback = useCallback(() => () => setOpen(false), [ref]);
  useOnClickOutside(ref, closeFnCallback);

  const handleChangePassword = () => {
    if (user?.data.info.email) {
      changePassword(user?.data?.info?.email, () => setOpen(false));
    }
  };
  const handleShowTermsAndConditions = () =>
    setTermsAndConditionsOpen(!termsAndConditionsOpen);

  return (
    <div className={styles.container} data-testid={"user-menu"}>
      <FaUserCog size={30} color="#6B97F6" />

      {formatFirstAndLastNames(
        user?.data?.info?.firstName,
        user?.data?.info?.lastName
      )}
      <IconButton dataTestId="user-menu-open-button" onClick={handleOpen}>
        <GoChevronDown />
      </IconButton>
      {open && (
        <div
          data-testid={"user-menu-content"}
          ref={ref}
          className={styles.userMenu}
          id="user-menu"
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.2rem",
              marginBottom: "1rem",
              background: user?.data.dealership?.data?.info
                ?.dealershipNameForDealershipPlatform
                ? "rgb(243, 243, 243)"
                : "#b7cdff",

              cursor: "default !important",
              padding: 0,
            }}
            className={styles.userMenuItem}
          >
            {user?.data.dealership?.data?.info
              ?.dealershipNameForDealershipPlatform && (
              <div
                style={{
                  textAlign: "center",
                  marginBottom: 5,
                  backgroundColor: "#b7cdff",
                  padding: 10,
                }}
              >
                {
                  user?.data.dealership?.data?.info
                    ?.dealershipNameForDealershipPlatform
                }
              </div>
            )}
            <div
              style={{
                textAlign: "center",
                padding: 10,
              }}
            >
              {user?.data.info.email}
            </div>
          </div>

          <div
            data-testid={"change-password-button"}
            className={styles.userMenuItem}
            onClick={handleChangePassword}
          >
            Change Password
          </div>
          <div
            data-testid={"terms-and-conditions"}
            className={styles.userMenuItem}
            onClick={handleShowTermsAndConditions}
          >
            Terms And Conditions
          </div>
          <div className={styles.userMenuItem} onClick={() => signOut(user)}>
            Sign out
          </div>
        </div>
      )}
      <TermsAndConditionsDialog
        open={termsAndConditionsOpen}
        setOpen={setTermsAndConditionsOpen}
      />
    </div>
  );
};

export default UserMenu;
