import Grid from "@Components/Grid";
import { Deal } from "@Types/deal";
import { Fragment, useEffect, useRef, useState } from "react";
import { IoIosWarning } from "react-icons/io";
import { IoDocumentOutline, IoEyeOutline } from "react-icons/io5";

import IFrameURLModal from "../PreviewDocuments/IFrameURLModal";

import Button from "@Components/Button";
import Loading from "@Components/Loading";
import Tooltip from "@Components/Tooltip";
import useCurrentUser from "@Hooks/useCurrentUser";
import { useUploadStipulation } from "@Hooks/useStipulations";
import { Stipulation } from "@Types/stipulations";
import { hasSectionPermissions } from "@Utils/permissions";
import mixpanel from "mixpanel-browser";
import CreateStipulationDialog from "./CreateStipulationDialog";
import styles from "./UploadStipulations.module.scss";

type UploadStipulationsProps = {
  deal: Deal;
  refetchDeal: () => void;
};
const UploadStipulations = ({ deal, refetchDeal }: UploadStipulationsProps) => {
  const currentUser = useCurrentUser();
  if (!hasSectionPermissions("deal", "documents", currentUser)) return null;
  const stipulations =
    deal.data?.stipulations?.filter((s) =>
      s.data?.info?.canBeUploadedBy?.includes("dealer")
    ) ?? [];

  const { uploadStipulation, loading } = useUploadStipulation(refetchDeal);
  const [stipulationForDialog, setStipulationForDialog] = useState<
    Stipulation | undefined
  >(undefined);
  const inputFile = useRef<HTMLInputElement | null>(null);
  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    stipulation: Stipulation
  ) => {
    if (e?.target?.files && e?.target?.files?.length) {
      handleUploadStipulation(e.target.files[0], stipulation);
    }
  };
  const user = useCurrentUser();
  const handleUploadStipulation = (file: File, stipulation: Stipulation) => {
    uploadStipulation({
      ...stipulation,
      file,
    });
    mixpanel.track("Stipulation upload", {
      email: user?.data?.info?.email,
      dealershipName: user?.data?.dealership?.data?.info?.name,
    });
  };
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const listWindowRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (listWindowRef.current) {
      listWindowRef.current.scrollTo({
        top: listWindowRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [stipulations]); // Runs every time the messages change

  return (
    <>
      <Grid container xs={12}>
        <div className={styles.container} id="uploadStipulations" tabIndex={-1}>
          <Grid xs={12}>
            <p className={styles.title}>Stipulations</p>
          </Grid>
          <Grid xs={12} ref={listWindowRef} className={styles.docs}>
            <Grid container xs={12}>
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Loading size={18} text={"Loading"} />
                </div>
              )}
              {!loading &&
                (stipulations ?? [])?.map((stipulation, index) => (
                  <Grid
                    key={index}
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                    className={styles.item}
                  >
                    {stipulation?.data?.info?.url && (
                      <>
                        <Grid xs={1}>
                          <IoDocumentOutline
                            size={40}
                            color={
                              stipulation?.data?.info?.url
                                ? "#6B97F6"
                                : "#A5AEC1"
                            }
                          />
                        </Grid>
                        <Grid xs={11} container alignItems="center">
                          <Grid xs={8}>
                            <span className={styles.documentName}>
                              {stipulation?.data?.info?.description}
                            </span>
                          </Grid>
                          <Grid xs={3}>
                            <div
                              className={styles.documentName}
                              style={{
                                whiteSpace: "nowrap",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div style={{ marginRight: 10 }}>Status:</div>
                              {stipulation?.data?.info?.status === "pending" ? (
                                <div>
                                  awaiting
                                  <br />
                                  approval
                                </div>
                              ) : (
                                stipulation?.data?.info?.status
                              )}
                            </div>
                          </Grid>

                          <Grid xs={1}>
                            <IoEyeOutline
                              size={20}
                              onClick={() =>
                                setStipulationForDialog(stipulation)
                              }
                              color="#6B97F6"
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                                marginTop: "2.5px",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {!stipulation?.data?.info?.url && (
                      <>
                        <Grid xs={1}>
                          <Tooltip
                            content={"Please upload the stipulation!"}
                            tooltipStyle={{
                              background: "#fff",
                              color: "#000",
                              padding: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            <IoIosWarning size={40} color={"#ffcc00"} />
                          </Tooltip>
                        </Grid>
                        <Grid xs={11} container alignItems="center">
                          <Grid xs={8} container>
                            <Grid xs={12}>
                              <span className={styles.documentName}>
                                {stipulation?.data?.info?.description}
                              </span>
                            </Grid>
                          </Grid>
                          <Grid xs={4}>
                            <Button
                              variant="blue"
                              className={styles.button}
                              style={{ paddingLeft: 10, paddingRight: 10 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                document
                                  .getElementById(`file-${stipulation?._id}`)
                                  ?.click();
                              }}
                            >
                              <Fragment>
                                Upload
                                <input
                                  ref={inputFile}
                                  type="file"
                                  id={`file-${stipulation?._id}`}
                                  hidden
                                  onChange={(e) => {
                                    handleFileChange(e, stipulation);
                                  }}
                                />
                              </Fragment>
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                ))}
            </Grid>
          </Grid>

          {/* <Button
            style={{ marginTop: 30 }}
            onClick={() => setOpenCreateDialog(true)}
            variant="blue"
          >
            Request stipulation from applicant
          </Button> */}
        </div>
      </Grid>
      <CreateStipulationDialog
        refetchDeal={refetchDeal}
        deal={deal}
        openCreateDialog={openCreateDialog}
        setOpenCreateDialog={setOpenCreateDialog}
      />
      {stipulationForDialog && stipulationForDialog?.data?.info?.url && (
        <IFrameURLModal
          fileName={stipulationForDialog?.data?.info?.description}
          url={stipulationForDialog?.data?.info?.url}
          closeDialog={() => setStipulationForDialog(undefined)}
        />
      )}
    </>
  );
};

export default UploadStipulations;
